import React from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import Card from "../../components/Card"
import { makeFakeEAN13 } from "../../../utils"
import ImageInput from "../components/ImageInput"
import {
  updateBarcode,
  updateError,
  fetchNextEssayError,
} from "../services/batch_error"
import Loading from "../../components/Loading"
import SubscriptionInputGroup from "../../subscriptions/components/SubscriptionInputGroup"
import { retrieveSubscription } from "../../subscriptions/services/subscriptions"

class BarcodeInputPage extends React.Component {
  state = {
    image: undefined,
    loading: true,
    barcode: undefined,
  };

  componentDidMount = () => {
    this.fetchNextImage()
  };

  fetchNextImage = () => {
    this.setState( { loading: true } )
    fetchNextEssayError()
      .then( ( image ) =>
        this.setState( {
          image: { key: image.original_key, id: image.id },
          loading: false,
          barcode: "",
        } )
      )
      .catch( ( error ) => {
        const status = get( error, "response.status" )
        if ( status === 404 ) {
          const { history } = this.props
          history.push( "/auditoria" )
        }
      } )
  };

  handleSubmit = ( barcode ) => {
    this.setState( { loading: true } )
    const { image } = this.state
    updateBarcode( image.id, { barcode } )
      .then( () => this.fetchNextImage() )
      .catch( () => this.setState( { loading: false } ) )
  };

  handleSkip = () => {
    this.setState( { loading: true } )
    const { image } = this.state
    updateError( image.id, {} )
      .then( () => this.fetchNextImage() )
      .catch( () => this.setState( { loading: false } ) )
  };

  fetchBarcode = async ( subscriptionId ) => {
    if ( subscriptionId ) {
      const subscription = await retrieveSubscription( subscriptionId )
      this.setState( { barcode: makeFakeEAN13( `${subscription.enrolment_key}` ) } )
    } else {
      this.setState( { barcode: "" } )
    }
  };

  render () {
    const { image, loading, barcode } = this.state

    return (
      <Card isFluid>
        <section className="validador-images">
          <div className="row">
            <Loading loading={loading}>
              <div className="col-md-12 mb-3">
                <SubscriptionInputGroup
                  onSelectSubscription={this.fetchBarcode}
                />
              </div>
              <ImageInput
                image={image}
                loading={loading}
                onSubmit={this.handleSubmit}
                onClick={this.handleSkip}
                forceValue={barcode}
              />
            </Loading>
          </div>
        </section>
      </Card>
    )
  }
}

BarcodeInputPage.propTypes = {
  history: PropTypes.shape( {
    push: PropTypes.func,
  } ).isRequired,
}

export default withRouter( BarcodeInputPage )
