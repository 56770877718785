import { toast } from "react-toastify"

export const lineToObject = ( shape ) => {
  const attrs = shape.getAttrs()
  const shouldSave = ["strokeWidth", "stroke", "offsetX", "offsetY", "points"]
  const object = {}
  shouldSave.forEach( ( attr ) => {
    object[attr] = attrs[attr]
  } )
  return object
}

export const arrowToObject = ( shape ) => {
  const attrs = shape.getAttrs()
  const shouldSave = [
    "strokeWidth",
    "stroke",
    "pointerLength",
    "pointerWidth",
    "x",
    "y",
    "points",
    "scaleX",
    "scaleY",
    "rotation",
  ]
  const object = {}
  shouldSave.forEach( ( attr ) => {
    object[attr] = attrs[attr]
  } )
  return object
}

export const getPointsDistance = ( p1, p2 ) =>
  Math.sqrt( ( p1.x - p2.x ) ** 2 + ( p1.y - p2.y ) ** 2 )

const TOAST_DURATION = 10000
export const toastConfig = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: TOAST_DURATION,
  hideProgressBar: true,
}

export const getShapes = ( data ) => {
  let shapes
  try {
    shapes = JSON.parse( data )
    if ( !Array.isArray( shapes.arrows ) ) {
      shapes.arrows = []
    }
    if ( !Array.isArray( shapes.lines ) ) {
      shapes.lines = []
    }
  } catch ( _ ) {
    shapes = {
      lines: [],
      arrows: [],
    }
  }
  return shapes
}

export const makeFakeEAN13 = ( value ) => {
  return value
}
